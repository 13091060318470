const unitConfig = {
    unit: {
        label: 'Unit',
        maxlength: '10',
        description: 'Unit'
    }
};

function addStepAttribute(fld) {
  var numberStepField = $(fld).find("input[name='step']");
  numberStepField[0].setAttribute('type','text')
  numberStepField[0].setAttribute('value','any')
}

module.exports = {
    disableFields: ['button', 'autocomplete'],
    disabledAttrs: 'access',
    controlPosition: 'right',
    disabledActionButtons: ['clear', 'save'],
    // typeUserEvents: typeUserEvents,
    // Add unit field to text and number widgets
    typeUserAttrs: {
      text: unitConfig,
      number: unitConfig
    },
    typeUserEvents: {
      number: {
        onadd: function(fld) {
          addStepAttribute(fld);
        }
      }
    }
};
