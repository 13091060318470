export function drawGraph(chartId, analyteName, containerBarcode, chartData) {
  Highcharts.chart(chartId, {
    chart: {
      type: 'scatter',
      zoomType: 'xy'
    },
    legend: {
      enabled: false,
    },
    title: {
      text: `Plate Results for ${analyteName} - ${containerBarcode}`
    },
    xAxis: {
      title: {
        text: 'Positions',
      },
      labels: {
        rotation: 270,
        style: {
          fontSize: 8,
        },
      },
      gridLineWidth: 0,
      showLastLabel: true,
      maxStaggerLines: 1,
      type: 'category',
    },
    yAxis: {
      title: {
        text: 'Concentration',
      }
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 5, // Larger dots and border requested by facility for easier viewing
          enabled: true,
          lineWidth: 1,
          lineColor: 'rgba(100,100,100)',
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '<b>Well:</b> {point.name}<br/><b>Sample:</b> {point.sample}<br/><b>Rep:</b> {point.rep}<br/><b>Concentration:</b> {point.y}<br/><b>Weight:</b> {point.weight}'
        }
      },
      series: {
        cursor: 'pointer',
        allowPointSelect: true,
        point: {
          events: {
            click: function (event) {
              window.open('/batch_samples/' + event.point.id, '_blank')
            }
          }
        }
      },
    },
    series: [
      {
        name: 'Plate Results',
        turboThreshold: 0, // No limit, default was 1000 objects then graph is blank
        data: chartData
      },
    ],
  });
}

export function drawStandards(standardChartId, analyteName, batchContainerUrl, standardsData, standardsMinMaxData) {
  Highcharts.chart(standardChartId, {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      displayErrors: true,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: `Raw Results for ${analyteName} Standards`
    },
    xAxis: {
      title: {
        text: 'Concentration',
      },
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: 'Absorbance',
      }
    },
    plotOptions: {
      scatter: {
        allowPointSelect: true,
        marker: {
          radius: 5,
          enabled: true,
          lineWidth: 1,
          lineColor: 'rgba(100,100,100)',
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '<b>Concentration:</b> {point.x}<br/><b>Absorbance:</b> {point.y}'
        },
      },
      series: {
        cursor: 'pointer',
        allowPointSelect: true,
        point: {
          events: {
            click: function () {
              location.href = batchContainerUrl + this.options.key;
            }
          }
        }
      },
    },
    series: [
      {
        name: 'Raw Standard Results',
        data: standardsData
      },
      {
        type: 'line',
        name: 'Regression Line',
        data: standardsMinMaxData,
        marker: {
          enabled: false
        },
        states: {
          hover: {
            lineWidth: 0
          }
        },
        enableMouseTracking: false,
      }
    ],
  });
}

export default {drawGraph, drawStandards};