// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/*****************************************************************************/
/*                              Default Dependencies                         */
/*****************************************************************************/

require("@rails/ujs").start();

require("turbolinks").start();
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="toggle"]').bootstrapToggle();
});

require("@rails/activestorage").start();

require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


/*****************************************************************************/
/*                          Application Dependencies                         */
/*****************************************************************************/

import $ from 'jquery';

global.$ = $;
global.jQuery = $;

require("jquery-ui/ui/widgets/sortable");

require('formBuilder/dist/form-builder.min');
require('formBuilder/dist/form-render.min');
require('dropzone/dist/min/dropzone.min');

import "bootstrap";
import "bootstrap4-toggle"

import "@fortawesome/fontawesome-free/js/all";

import LocalTime from "local-time"

LocalTime.start();

global.moment = require('moment');

/*****************************************************************************/
/*                              Custom Javascript                            */
/*****************************************************************************/

require("../custom/asyncScanTablePlugin");

global.formBuilderDefaultConfig = require("../custom/formBuilderDefaultConfig");

import {drawGraph, drawStandards} from '../custom/drawCharts';

global.drawGraph = drawGraph;
global.drawStandards = drawStandards;